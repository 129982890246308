<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel panel-primary">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="serviceIcon" alt=""> 
				        	Buff {{ serviceName }} {{ formService.provider | ucfirst }} 
				        	<router-link :to="listURL" class="btn bg-main btn-sm"><i class="fa fa-list"></i> Danh sách ID</router-link>
				        </h3>

				        <Notification :data="noti" :key="k" v-for="(noti, k) in notification.service"></Notification>

				        <form action="#" @submit.prevent="buyService" v-if="hasService">
				        	<div class="form-group">
				        		<label>ID hoặc Link :</label>
				        		<input type="text" class="form-control" placeholder="Nhập ID hoặc Link" v-model="formService.id">
				        	</div>
				        	
				        	<div class="form-group">
				        		<label>Ghi chú :</label>
				        		<textarea class="form-control" v-model="formService.note" rows="2" placeholder="Nhập ghi chú hoặc bỏ trống"></textarea>
				        	</div>

				        	<div class="form-group">
				        		<label>Chọn Server <i class="fa fa-sort" @click="sortType"></i> :</label>
				        		<div class="form-check" v-if="serviceType" v-for="(sv, key) in serviceType">
								    <label class="form-check-label" :for="sv.type">
								    	<input class="form-check-input" type="radio" name="type" v-model="formService.type" :value="sv.type" :id="sv.type" :checked="(key == 0 ? true : false)" @change="checkType()" style="margin-right:3px;">
								       	<span class="label b-danger">SV{{ key + 1 }}</span> <span class="label b-info">{{ sv.price | numberFormat }}đ</span> {{ sv.name }}
								    </label>
								</div>
				        	</div>

				        	<div class="alert b-warning">Giá dịch vụ của bạn thuộc loại {{ user.roleText }} ( Giảm {{ user.discount }}% ) <router-link to="/price" class="au">Xem bảng giá dịch vụ và nâng cấp!</router-link></div>

				        	<div class="form-group" v-if="formService.type.includes('eye_live_stream')">
				        		<label>Số phút xem :</label>
				        		<select class="form-control" v-model="formService.minute_view">
				        			<option :value="minview" v-for="minview in minuteViewLists">{{ minview }} phút</option>
				        		</select>
				        	</div>

				        	<div v-if="hasComment">
				        		<div class="form-group">
					        		<label>{{ formService.type.indexOf('cmt') > -1 ? 'Nội dung bình luận' : 'Nội dung đánh giá' }} ( {{ serviceData.min | numberFormat }} ~ {{ serviceData.max | numberFormat }} ) <span class="label b-info">{{ commentCount }}</span> :</label>
					        		<textarea v-model="formService.comments" class="form-control" placeholder="Nhập nội dung bình luận, mỗi nội dung khác nhau thì xuống hàng" :rows="commentRows" @input="countComment" required=""></textarea>
					        		<div class="alert b-danger" v-if="formService.type.indexOf('review_fanpage') > -1" style="margin-top:10px;">Chú ý : Mỗi nội dung đánh giá tối thiểu 25 ký tự</div>
					        	</div>
					        	<!-- <div class="form-group" v-if="formService.provider == 'facebook' && formService.type.indexOf('cmt_2') > -1">
					        		<label>Bình luận kèm ảnh (Link):</label>
					        		<textarea v-model="formService.images" class="form-control" placeholder="Nhập link hình ảnh, mỗi dòng 1 ảnh" rows="3"></textarea>
					        		<div class="help-block mt-2">
					        			<font color="red">*</font> Link ảnh ví dụ: <b class="text-primary">{{$store.state.base_url}}/imgs/anhdep.png</b> (Kết thúc đuôi ảnh là .png, jpg, jpge, .gif) <b>nhập nhiều để không bị trùng ảnh!</b>
					        		</div>
					        	</div> -->
				        	</div>

				        	

				        	<div class="form-group" v-else="">
				        		<label>Số lượng cần tăng ( {{ serviceData.min | numberFormat }} ~ {{ serviceData.max | numberFormat }} ) :</label>
				        		<vue-numeric class="form-control" v-bind:minus="false" separator="," v-model="formService.quantity" placeholder="Nhập số lượng cần tăng"></vue-numeric>
				        		
				        		<div class="form-check form-switch pt-2">
								    <label class="form-check-label" for="buyAllMoney">
								    	<input class="form-check-input" type="checkbox" id="buyAllMoney" v-model="isBuyAll" :checked="isBuyAll" @click="isBuyAll = $event.target.checked"> 
								    	Mua với tất cả số dư hiện tại? <div class="label b-success">{{ user.money | numberFormat }} {{ settings.currency }}</div>
								    </label>
								</div>

				        	</div>
				        	<div class="form-group">
				        		<label>Chế độ :</label>
				        		<select class="form-control" v-model="formService.price_type">
				        			<option value="0" v-if="this.serviceData.price">Không bảo hành - {{ this.serviceData.price | numberFormat }} {{ settings.currency }}</option>
				        			<option value="1" v-if="this.serviceData.price_warranty" selected="">Có bảo hành - {{ this.serviceData.price_warranty | numberFormat }} {{ settings.currency }} ( nên chọn )</option>
				        		</select>
				        		<div class="help-block alert mt-2" :class="[formService.price_type == 0 ? 'b-danger' : 'b-info']">Nên chọn có bảo hành để dịch vụ được chạy tốt và ưu tiên nhất có thể!</div>
				        	</div>

				        	<div class="form-group" v-if="hasReaction">
				        		<label>Cảm xúc :</label>
				        		<div class="text-center">
				        			<div class="box-reactions">
				        				<div v-for="(reactions, k) in serviceReactions" :class="{'reaction': true, active: k == selectedReaction}" @click="selectReaction(k)">
					        				<img :src="require('@/assets/images/reactions/'+ k +'.png')" alt="">
					        			</div>
				        			</div>
				        		</div>
				        	</div>

				        	<div class="form-group" v-if="filterPercent > 0">
				        		<label>Lọc giới tính :</label>
				        		<div class="row text-center">
				        			<div class="col-lg-4">
				        				<button type="button" class="btn btn-secondary btn-block gender" :class="{'active': this.formService.gender == 'male'}" @click="formService.gender = 'male'">
				        					<inline-svg :src="require('@/assets/images/icons/gender/male.svg')" />
					        				Nam ( + {{filterPercent}}% )
					        			</button>
				        			</div>
				        			<div class="col-lg-4">
				        				<button type="button" class="btn btn-secondary btn-block gender" :class="{'active': this.formService.gender == 'female'}" @click="formService.gender = 'female'">
				        					<inline-svg :src="require('@/assets/images/icons/gender/female.svg')" />
					        				Nữ ( + {{filterPercent}}% )
					        			</button>
				        			</div>
				        			<div class="col-lg-4">
				        				<button type="button" class="btn btn-secondary btn-block gender" :class="{'active': this.formService.gender == 'all'}" @click="formService.gender = 'all'">
				        					<inline-svg :src="require('@/assets/images/icons/gender/male.svg')" />
				        					<inline-svg :src="require('@/assets/images/icons/gender/female.svg')" />
					        				Tất cả
					        			</button>
				        			</div>
				        		</div>
				        	</div>

				        	<div class="form-group">
				        		<div class="box-price">
				        			<div class="text">Tổng thanh toán</div>

				        			<div class="total-money" ref="serviceMoney">{{ serviceTotalMoney | numberFormat }} {{ settings.currency }}</div>

				        			<div class="" ref="serviceText">Bạn sẽ tăng <span class="note-service">{{ formService.quantity | numberFormat }} {{ selectedReaction !== 'like' ? selectedReaction.toUpperCase() : serviceName }}</span> với giá <span class="note-service">{{ servicePrice | numberFormat }} {{ settings.currency }} / {{ selectedReaction !== 'like' ? selectedReaction.toUpperCase() : serviceName }}</span></div>
				        		</div>
				        	</div>


				        	<button class="btn b-info btn-lg btn-block">Thanh toán dịch vụ</button>
				        </form>

				        <div class="text-center alert bg-danger text-white" v-else>
				        	DỊCH VỤ KHÔNG KHẢ DỤNG HOẶC BẢO TRÌ - VUI LÒNG LIÊN HỆ ADMIN ĐỂ ĐƯỢC HỖ TRỢ !
				        </div>


				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped=""></style>

<script>

	import '@/assets/css/service.css';

	export default {
		props: ['user', 'services', 'settings', 'notification'],
		data () {
			return {
				formService: {
					price_type: 1,
					quantity: 0,
					minute_view: 30,
					gender: 'all'
				},
				minuteViewLists: [30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300],
				hasService: true,
				selectedReaction: 'like',
				commentRows: 6,
				commentCount: 1,
				totalPrice: 0,
				serviceReactions: {},
				// reactionsLists: ['like', 'care', 'love', 'haha', 'wow', 'sad', 'angry'],
				serviceSort: 'ASC',
				serviceName: '',
				serviceIcon: '',
				servicePrice: 0,
				serviceMin: 0,
				serviceType: [],
				hasComment: false,
				hasReaction: false,
				isBuyAll: false,
				service: this.services,
				serviceData: {
					min: 0,
					max: 0,
					price: 0
				},
				noteText: '',
				filterPercent: 0,
				listURL: ''
			}
		},
		created () {

			let servicePath = this.$route.params.service;
			servicePath = servicePath.split('_');

			this.formService.type 		= servicePath.slice(0, (servicePath.length - 1)).join('_');
			this.formService.provider 	= servicePath[(servicePath.length - 1)];

			this.listURL = 'services/' + (this.formService.type + '_' + this.formService.provider);

			if (Object.keys(this.service).length === 0) {
				this.$store.dispatch('siteData').then((res) => {
					this.service = res.services;
					this.serviceName = this.service[this.formService.provider + '_buff']['items'][this.formService.type]['name'];
					this.getType();
				});
			} else {
				this.serviceName = this.service[this.formService.provider + '_buff']['items'][this.formService.type]['name'];
				this.getType();
			}

			this.serviceIcon = require('@/assets/images/service/' + this.formService.provider + '/' + this.formService.type + '.png');

			if (this.formService.type == 'cmt' || this.formService.type == 'review_fanpage') {
				this.hasComment = true;
			}

		},
		/*watch: {
			service () {
				if (this.services) {
					console.log('hasloader');
				}
			}
		},*/
		computed: {
			/*service () {
				return this.services;
			},*/
			serviceTotalMoney () {

				if (this.formService.price_type == 0) {
					this.servicePrice = this.serviceData.price;
				} else {
					this.servicePrice = this.serviceData.price_warranty;
				}

				if (this.selectedReaction != 'like') {
					this.servicePrice = Math.ceil(this.servicePrice + this.serviceReactions[this.selectedReaction]);
				}

				if (this.formService.gender != 'all') {
					this.servicePrice = (this.servicePrice + this.servicePrice * this.filterPercent / 100);
				}

				if (this.isBuyAll && this.user.money > 0) {
					if (this.formService.type.includes('eye_live_stream')) {
						this.formService.quantity = (this.user.money / (this.servicePrice * this.formService.minute_view));
					} else {
						this.formService.quantity = (this.user.money / this.servicePrice);
					}
				}

				let total_money = (this.formService.quantity * this.servicePrice);

				if (this.formService.type.includes('eye_live_stream')) {
					total_money = (total_money * this.formService.minute_view);
				}

				return total_money;
			}
		},
		methods: {
			countComment () {
				this.commentCount = this.formService.comments.split("\n").filter(i => i).length;
				if (this.commentCount < 1) {
					this.commentCount = 1;
				}
				if ((this.commentCount + 1) >= 6) {
					this.commentRows = (this.commentCount + 1);
				} else {
					this.commentRows = 6;
				}
				this.formService.quantity = this.commentCount;
			},
			checkType () {

				this.serviceReactions = {};
			
				this.serviceData = this.serviceType.filter(x => x.type == this.formService.type)[0];

				this.formService.gender = 'all';

				this.hasReaction = false;

				this.selectReaction('like');

				this.filterPercent = this.serviceData.filter_percent;

				if (this.serviceData.context) {

					try {
						this.serviceData.context = JSON.parse(this.serviceData.context);
					} catch {}

					this.serviceReactions.like = 0;

					Object.keys(this.serviceData.context).filter(k => {
						this.serviceReactions[k] = this.serviceData.context[k];
					});

					if (this.serviceReactions.love > 0 || this.serviceReactions.love == 0) {
						this.hasReaction = true;
					}

				}

				if ((this.formService.type.includes('cmt') || this.formService.type.includes('review_fanpage')) && 
					!this.formService.type.includes('like_cmt')) {
					this.formService.quantity = this.commentCount;
				} else {
					this.formService.quantity = this.serviceData.min;
				}

				if (this.serviceData.price_warranty > 0) {
					this.formService.price_type = 1;
				} else {
					this.formService.price_type = 0;
				}

			},
			selectReaction (reactions) {
				this.selectedReaction = reactions;
				this.formService.reactions = reactions;
			},
			getType () {
				this.$http.post('service/type', this.formService).then(res => {
					if (res.body.length === 0) {
						this.hasService = false;
					}
					this.serviceType = res.body;
					this.formService.type = this.serviceType[0].type;
					this.checkType();
				});
			},
			sortType () {
				if (this.serviceSort == 'ASC') {
					this.serviceSort = 'DESC';
					this.serviceType.sort((a, b) => a.price - b.price);
				} else {
					this.serviceSort = 'ASC';
					this.serviceType.sort((a, b) => b.price - a.price);
				}
			},
			buyService () {
				let icon = this.serviceIcon;
				if (this.selectedReaction !== 'like') {
					icon = require('@/assets/images/reactions/' + this.selectedReaction + '.png');
				}
				if (!this.formService.type.includes('eye_live_stream')) {
					delete this.formService.minute_view;
				}
				if (!this.formService.id) {
					return this.$swal(
						'Thông báo',
						'Vui lòng nhập ID hoặc Link cần thực hiện tăng ' + this.serviceName,
						'error'
					);
				}
				if (this.formService.quantity < this.serviceData.min) {
					if (!this.formService.comments) {
						this.formService.quantity = this.serviceData.min;
					}
					return this.$swal(
						'Thông báo',
						'Số lượng mua ít nhất là ' + this.$options.filters.numberFormat(this.serviceData.min) + ' ' + this.serviceName,
						'error'
					);
				}
				if (this.formService.quantity > this.serviceData.max) {
					if (!this.formService.comments) {
						this.formService.quantity = this.serviceData.max;
					}
					return this.$swal(
						'Thông báo',
						'Số lượng mua tối đa là ' + this.$options.filters.numberFormat(this.serviceData.max) + ' ' + this.serviceName, 
						'error'
					);
				}
				this.$swal({
					title: 'Xác nhận ?',
					html: this.$refs.serviceText.innerHTML + '<br> Tổng thanh toán : <b>' + this.$refs.serviceMoney.innerHTML + '</b>',
					imageUrl: icon,
					imageHeight: '100px',
					showCancelButton: true,
					confirmButtonText: 'Thanh toán'
				}).then((isConfirm) => {
					if (isConfirm.value) {
						this.$http.post('service/buy', this.formService).then(res => {
							res.body.message = (res.body.message_html ? res.body.message_html : res.body.message)
							if (res.body.type == 'no_money') {
								this.$swal({
									icon: 'error',
									html: res.body.message,
									showCancelButton: 1,
									confirmButtonText: 'Nạp tiền'
								}).then((isConfirm2) => {
									if (isConfirm2.value) {
										this.$router.push('/payment');
									}
								});
							} else {
								if (res.body.status == 1) {
									this.$swal({
										icon: 'success',
										text: res.body.message,
										cancelButtonText: 'Danh sách',
										confirmButtonText: 'Mua tiếp',
										showCancelButton: 1
									}).then((isConfirm) => {
										if (!isConfirm.value) {
											this.$router.push('/' + this.listURL);
										}
									});
								} else {
									this.$swal('Thông báo', res.body.message, 'error');
								}
							}
						});
					}
				});
				//console.log(this.formService)
			}
		}
	}
</script>